import {AgRendererComponent} from 'ag-grid-angular';
import {Component}           from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
    selector:    'link-cell-popup',
    templateUrl: './link-cell-popup.component.html',
})
export class LinkCellPopUpComponent implements AgRendererComponent
{
    public params!: ICellRendererParams;

    public agInit(params: ICellRendererParams): void
    {
        this.params = params;
    }

    public refresh(params: ICellRendererParams): boolean
    {
        return false;
    }
}
