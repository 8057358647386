import {AgRendererComponent} from 'ag-grid-angular';
import {Component}           from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community';
import {Router} from '@angular/router'
@Component({
    selector:    'link-renderer',
    templateUrl: './link-renderer.component.html',
})
export class LinkRendererComponent implements AgRendererComponent
{
    constructor(private router:Router){

    }
    public params!: ICellRendererParams;

    public agInit(params: ICellRendererParams): void    {
        
        this.params = params;
    }

    public refresh(params: ICellRendererParams): boolean
    {
        return false;
    }
}
