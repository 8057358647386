

import { Component, OnInit} from '@angular/core';
import { FormBuilder,FormGroup, Validators}  from '@angular/forms';
import { MatDialog, MatDialogRef}            from '@angular/material/dialog';
import { MatSnackBar}                        from '@angular/material/snack-bar';
import { DataService } from '../../../../../shared/services/data.service';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
    selector:    'app-search-organisation',
    templateUrl: './search-organisation.component.html',
})
export class SearchOrganisationComponent implements OnInit
{
    public organisationForm: FormGroup;
    public error: any;
    public submitted = false;   
    private searchSubscription?: Subscription;
    organizationInfo:any;
    selectedOrganisation:any;
    private readonly searchSubject = new Subject<string | undefined>();
    public constructor(
        public dialogRef: MatDialogRef<SearchOrganisationComponent>,
        private fb: FormBuilder,public dataService:DataService,
        private snackBar: MatSnackBar,public dialog: MatDialog,
    )
    
    // Pankaj, 29-Feb-23, Ticket-3620,Companies House integration,search organization by name or company number
    {
        this.searchSubscription = this.searchSubject.pipe(
            debounceTime(400) // Adjust the debounce time (in milliseconds) as needed
          ).subscribe(searchTerm => {
            if (searchTerm && searchTerm.length > 3) {
              let payload = { "company": searchTerm };
              this.dataService.create('organisations/getcompanyaddress', payload).subscribe({
                next: (response: any) => {
                  this.organizationInfo = response;
                  console.log("org = ", JSON.stringify(this.organizationInfo));
                },
                error: (response) => {
                  this.snackBar.open(response.error.message, 'Ok.', { duration: 2000 });
                },
              });
            }
        });
    }
    
    searchOrganization(searchTerm: string) {
        this.searchSubject.next(searchTerm);
    } 

    displayOrganization(org: any): string{
        return org 
    }
    
    selectOrganization(event: MatAutocompleteSelectedEvent): void {
        this.selectedOrganisation = event.option.value;
        this.organisationForm.get('name').setValue(this.selectedOrganisation.title+', '+this.selectedOrganisation.company_number+', '+this.selectedOrganisation?.address?.address_line_1+', '+ this.selectedOrganisation?.address?.locality+', '+this.selectedOrganisation?.address?.postal_code);  
    }

    public get form()
    {
        return this.organisationForm.controls;
    }

    public ngOnInit(): void
    {
        this.organisationForm = this.fb.group({
            name: [null,[Validators.required]]
        })
    }

    onSubmit(event){
        if(event=='close'){
            this.dialogRef.close()
        }else{
            this.submitted = true;
            this.organisationForm.markAllAsTouched();
            if (this.organisationForm.invalid) {
                this.submitted = false; 
                return;
            }
            this.dialogRef.close(this.selectedOrganisation)
        }
    }
}
