import {ICellRendererAngularComp} from 'ag-grid-angular';
import {Component}                from '@angular/core';
import {ICellRendererParams}      from 'ag-grid-community';

@Component({
    selector:    'binary-icon-cell-renderer',
    templateUrl: './binary-icon-cell-renderer.component.html',
})
export class BinaryIconCellRendererComponent implements ICellRendererAngularComp
{
    public params!: any;

    public agInit(params: ICellRendererParams): void
    {
        this.params = params;
    }

    public refresh(params: ICellRendererParams): boolean
    {
        return false;
    }
}
