import {Injectable} from '@angular/core';
import {BaseService} from '../../../shared/services/base.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService extends BaseService
{
    public constructor()
    {
        super();
    }

    public get roles(): String[]
    {
        const userRoles = localStorage.getItem('roles');
        return userRoles !== 'undefined' ? JSON.parse(userRoles) : null;
    }

    public set roles(roles: String[])
    {
        localStorage.setItem('roles', JSON.stringify(roles));
    }

    public get permissions(): String[]
    {
        const perms = localStorage.getItem('permissions');
        return perms !== 'undefined' ? JSON.parse(perms) : null;
    }

    public set permissions(permissions: String[])
    {
        localStorage.setItem('permissions', JSON.stringify(permissions));
    }

    public can(permission: string)
    {
        return this.permissions !== null
            ? this.permissions.find(p => p === permission)
            : false;
    }

    public cant(permission: string)
    {
         return this.permissions !== null
            ? !this.permissions.find(p => p === permission)
            : false;
    }

    public hasRole(role: string)
    {
        return this.roles !== null
            ? this.roles.find(p => p === role)
            : false;
    }
}
