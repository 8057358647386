import {Injectable} from '@angular/core';
import {Observable, of,BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export abstract class BaseService
{
    protected apiUrl: string;
    menuPopulation=new BehaviorSubject<any>(false);
    protected constructor()
    {
        this.apiUrl = environment.apiUrl;
    }

    public handleError<T>(result?: T)
    {
        return (error: any): Observable<T> => {
            console.error(error);
            return of(result as T);
        };
    }
}
