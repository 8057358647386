import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {PermissionsService} from '../../core/auth/services/permissions.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionGuard implements CanActivate, CanActivateChild, CanLoad
{
    /**
     * Constructor
     */
    public constructor(
        private permissionService: PermissionsService,
        private router: Router,
    )
    {
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const redirectUrl = state.url === '/logout' ? '/' : state.url;
        return this.can(route.data.permission, redirectUrl);
    }

    public canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const redirectUrl = state.url === '/logout' ? '/' : state.url;
        return this.can(childRoute.data.permission, redirectUrl);
    }

    public canLoad(
        route: Route,
        segments: UrlSegment[],
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this.can(route.data.permission, '/');
    }

    private can(permission, redirectUrl): Observable<boolean>
    {
        if (permission && !this.permissionService.can(permission) && !this.permissionService.hasRole('developer')) {
            this.router.navigate(['login'], {queryParams: {redirectUrl}}).then(r => r);
            return of(false);
        }

        return of(true);
    }
}
