import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, from, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { UsersService } from '../../../modules/users/services/users.service';
import { BaseService } from '../../../shared/services/base.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PermissionsService } from './permissions.service';
import { CommonListValuesService } from '../../../shared/services/common-list-values.service';
import { SettingsService } from './settings.service';
import { DataService } from '../../../shared/services/data.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends BaseService {
    public authenticated: boolean = false;

    public constructor(
        public jwtHelper: JwtHelperService,
        private httpClient: HttpClient,
        private router: Router,
        private permissionsService: PermissionsService,
        private usersService: UsersService,
        private dataService: DataService,
        private commonListValuesService: CommonListValuesService,
        private settingsServices: SettingsService,
    ) {
        super();
    }

    public get accessToken(): string {
        return localStorage.getItem('accessToken') ?? null;
    }

    public set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    public login(credentials: { email: string, password: string }): Promise<any> {
        if (this.authenticated) {
            this.handleError('User is already logged in.');
        }

        return new Promise((r, rj) => {
            return this.httpClient.post(`${this.apiUrl}/login`, credentials).subscribe(response => {
                this.authenticateUser(response, true).subscribe((v) => {
                    r(response);
                });
            }, e => rj(e))
        });

    }

    public signInUsingToken(): Observable<any> {
        if (this.accessToken) {
            return this.httpClient.post(`${this.apiUrl}/refresh`, {
                token: this.accessToken,
            }).pipe(
                catchError(() => of(false)),
                switchMap((response: any) => {
                    this.authenticateUser(response);
                    return of(true);
                }),
            );
        }

        return of(false);
    }

    public logout(): Observable<any> {
        this.deauthenticateUser();
        return of(true);
    }

    public forgotPassword(email: string): Observable<any> {
        return this.httpClient
            .post<any>(`${this.apiUrl}/forgot-password`, email)
            .pipe(switchMap((response: any) => of(response)));
    }

    public resetPassword(token: string, password: string): Observable<any> {
        return this.httpClient
            .post<any>(`${this.apiUrl}/reset-password/${token}`, password)
            .pipe(switchMap((response: any) => of(response)));
    }

    public check(): Observable<boolean> {
        if (this.accessToken === 'undefined') {
            return of(false);
        }

        if (this.jwtHelper.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        if (this.authenticated) {
            return of(true);
        }

        if (!this.accessToken || this.accessToken === 'undefined') {
            return of(false);
        }

        return this.signInUsingToken();
    }

    private authenticateUser(response: any, updateLocalData: boolean = false) {
        this.accessToken = response.access_token;
        this.authenticated = true;
        this.usersService.user = response.user;
        this.permissionsService.roles = response.roles;
        this.permissionsService.permissions = response.permissions;
        this.settingsServices.authUser = response.user;
        this.scheduleTokenRefresh(response.expires_in);
        if (updateLocalData) {
            return of(null);
        } else {
            return of(null);
        }
    }

    private scheduleTokenRefresh(expiresIn: number) {
        const refreshTime = (expiresIn - 60) * 1000; // Schedule the refresh 1 minute before expiration
        console.log("refreshTime = ="+refreshTime)
        setTimeout(() => {
            this.refreshToken().subscribe();
        }, refreshTime);
    }

    public refreshToken(): Observable<any> {
        if (this.accessToken) {
            return this.httpClient.post(`${this.apiUrl}/refresh`, {
                token: this.accessToken,
            }).pipe(
                tap((response: any) => {
                    this.authenticateUser(response);
                }),
                catchError(() => {
                    this.deauthenticateUser();
                    return of(false);
                })
            );
        }        
    }

    private deauthenticateUser() {
        this.authenticated = false;
        localStorage.removeItem('accessToken');
        localStorage.removeItem('roles');
        localStorage.removeItem('permissions');
        localStorage.removeItem('contactsDetails');
        localStorage.removeItem('orgDetails');
    }
}
