import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'limit'
})
export class LimitPipe implements PipeTransform
{
    public transform(
        value: string,
        limit: number = 25,
        completeWords: boolean = false,
        ellipsis: string = '&#8230;',
        slice: number = 0
    ): string
    {
        if (completeWords) {
            limit = value.substr(slice, limit).lastIndexOf(' ');
        }

        return value && value.length > limit
               ? value.substr(slice, limit) + ellipsis
               : value;
    }
}
