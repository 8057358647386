import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {IHeaderAngularComp} from "ag-grid-angular";
import { IHeaderParams } from 'ag-grid-community';
import { DataService } from '../../../services/data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customheader',
  templateUrl: './customheader.component.html',
  styleUrls: ['./customheader.component.css']
})
export class CustomHeaderComponent implements IHeaderAngularComp{
  private params : IHeaderParams;
  allRowsSelected:boolean=false
  private checkboxSubscription?: Subscription;
 
  agInit(params:IHeaderParams): void {
    console.log(params);
    this.params = params;
    this.dataService.headerSelection=this.params;
  }
 
  toggleSelectAll(event: MatCheckboxChange) {        
    const checked = event.checked;
    if(checked){
      this.params.api.forEachNode(node => node.setSelected(true));
    }else{
      this.params.api.forEachNode(node => node.setSelected(false));
    }
    this.dataService.selectAll=true;
  }

  selectedRowSubscription:Subscription;
  constructor(public dataService: DataService) {
   this.checkboxSubscription= this.dataService.checkboxHeader.subscribe(res=>{
      if (res){
        this.allRowsSelected=true;
      }else{
        this.allRowsSelected=false;
      }
    })
    this.selectedRowSubscription= this.dataService.deSeletedRow.subscribe(res=>{
      if (res){
        this.params.api.forEachNode(node => node.setSelected(false)); 
      }
    })
   }
  refresh(params: IHeaderParams<any>): boolean {
    throw new Error('Method not implemented.');
  }
  
  ngOnDestroy(): void {
    this.checkboxSubscription.unsubscribe();
    this.selectedRowSubscription.unsubscribe();
  }

}