import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
    name: 'defaultDate',
})
export class DefaultDatePipe extends DatePipe implements PipeTransform
{
    public transform(value: null | undefined): null;
    public transform(value: Date | string | number | null | undefined): string | null;
    public transform(value: Date | string | number | null | undefined): string | null
    {
        return super.transform(value, 'dd MMM y hh:mm a');
    }
}
