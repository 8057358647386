import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
    selector: "body",
    template: `
        <router-outlet></router-outlet>
    `,
    styleUrls: ["app.component.scss"],
    providers: []
})
export class AppComponent implements OnInit {
    public constructor(private router: Router) {}

    public ngOnInit() {
        this.router.events.subscribe(event => {
            if (!(event instanceof NavigationEnd)) {
                return;
            }

            window.scrollTo(0, 0);
        });
    }
}
