import {NgModule}                from '@angular/core';
import {RouterModule, Routes}    from '@angular/router';
import {LoginComponent}          from './components/login/login.component';
import {RegisterResolver}        from './resolvers/register.resolver';
import {RegisterComponent}       from './components/register/register.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {ResetPasswordComponent}  from './components/reset-password/reset-password.component';
import {NoAuthGuard}             from '../../shared/guards/no-auth.guard';

export const routes: Routes = [
    {
        path:        'login',
        canActivate: [NoAuthGuard],
        component:   LoginComponent,
        data:        {title: 'Login'},
    },
    {
        path:        'forgot-password',
        canActivate: [NoAuthGuard],
        component:   ForgotPasswordComponent,
        data:        {title: 'Forgot Password'},
    },
    {
        path:        'reset-password/:token',
        canActivate: [NoAuthGuard],
        component:   ResetPasswordComponent,
        data:        {title: 'Reset Password'},
    },
    {
        path:        'register/:token',
        // canActivate: [NoAuthGuard],
        component:   RegisterComponent,
        resolve:     {
            user: RegisterResolver,
        },
        data:        {
            title: 'Register',
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {
}
