import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {PermissionsService}                              from '../../core/auth/services/permissions.service';

@Directive({
    selector: '[appCan]',
})
export class CanDirective
{
    public constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private permissionsService: PermissionsService,
    )
    {
    }

    @Input()
    public set appCan(permission: string)
    {
        if (this.permissionsService.hasRole('developer') || this.permissionsService.can(permission)) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainerRef.clear();
        }
    }
}
