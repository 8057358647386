import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseService } from './base.service';
import { Params } from '@angular/router';
import { SettingsService } from '../../core/auth/services/settings.service';
import { SortModelItem } from 'ag-grid-community';

@Injectable({
    providedIn: 'root',
})
export abstract class DataService extends BaseService {
    quoteFilterApplied=new BehaviorSubject<any>(false);
    quoteUpdate=new BehaviorSubject<any>(false);
    opportunityFilterApplied=new BehaviorSubject<any>(false);
    myOpportunityFilterApplied=new BehaviorSubject<any>(false);
    purchaseOrderUpdate=new BehaviorSubject<any>(false);
    salesOrderLineItemUpdate = new BehaviorSubject<any>(false);
    priceListLineItemUpdate = new BehaviorSubject<any>(false);
    priceListAddLineItem=new BehaviorSubject<any>(false);
    updateOrgOpportunities=new BehaviorSubject<any>(false);
    updateOrgProject=new BehaviorSubject<any>(false);
    getIncommingEmail= new BehaviorSubject<any>(false);
    orgPriceListUpdate=new BehaviorSubject<any>(false);
    updateOrgContact=new BehaviorSubject<any>(false);
    updateSellOut=new BehaviorSubject<any>(false);
    updateOrgAddress=new BehaviorSubject<any>(false);
    updateOrgSalesOrder = new BehaviorSubject<any>(false);
    updateOrgQuotes = new BehaviorSubject<any>(false);
    projectSiteUpdate = new BehaviorSubject<any>(false);
    opportunitiesQuoteUpdate = new BehaviorSubject<any>(false);
    projectOpportunitiesUpdate = new BehaviorSubject<any>(false);
    projectOrganisationUpdate = new BehaviorSubject<any>(false);
    updateParentCompany = new BehaviorSubject<any>(false);
    allAssociateOrg = new BehaviorSubject<any>(false);
    updateQuteLineItem = new BehaviorSubject<any>(false);
    updateSubsidiaryCompany = new BehaviorSubject<any>(false);
    checkboxHeader = new BehaviorSubject<any>(false);
    deSeletedRow= new BehaviorSubject<any>(false);
    assetsWarrantyListUpdate=new BehaviorSubject<any>(false);
    assetsTicketListUpdate=new BehaviorSubject<any>(false);
    headerSelection:any;
    selectAll:boolean = false
    isSelectAllChecked:boolean = false;
    isSelectAllUnchecked:boolean = false;
    promotionProductChargeListUpdate=new BehaviorSubject<any>(false);
    promotionProductFreeListUpdate=new BehaviorSubject<any>(false);
    promotionCustomerListUpdate=new BehaviorSubject<any>(false);
    promotionCallListUpdate=new BehaviorSubject<any>(false);
    protected constructor(
        protected httpClient: HttpClient,
        private settingsServices: SettingsService
    ) {
        super();
    }

    public list(path: string, params: Params = null): Observable<any> {
        return this.httpClient
            .get(`${this.apiUrl}/${path}`, { params: params })
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public listWithCache(path: string, params: Params = null): Observable<any> {
        const cache = this.settingsServices.getValue(path);
        if (cache) {
            return of(cache);
        }
        return this.httpClient
            .get(`${this.apiUrl}/${path}`, { params: params })
            .pipe(switchMap((response: any) => {
                this.settingsServices.setValue(path, response);
                return of(response);
            }));
    }


    public show(path: string, id: string | number): Observable<any> {
        return this.httpClient
            .get<any>(`${this.apiUrl}/${path}/${id}`)
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public get_report(path: string): Observable<any> {
        return this.httpClient
            .get<any>(`${this.apiUrl}/${path}`)
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public create(path: string, data: any) {
        return this.httpClient
            .post<any>(`${this.apiUrl}/${path}`, data)
            .pipe(switchMap((response: any) => of(response)));
    }

    public update(path: string, data: any): Observable<any> {
        return this.httpClient
            .put<any>(`${this.apiUrl}/${path}`, data)
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public delete(path: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiUrl}/${path}`);
    }

    public deleteWithParam(path: string, params: Params = null): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiUrl}/${path}`, { params: params });
    }

    public searchDebounceTime(){
        return 400
    }

    public searchlength(){
        return 0    
    }

    public pageSize(){
        return 25
    }
    public dashboardPageSize(){
        return 5
    }
    
    // Pankaj, 03-April-24, make query for pagination,search and sort
    public buildQuery(sortModel?: SortModelItem[],pageNumber?,paginationPageSize?,searchItem?) {                
        const query = new URLSearchParams({
            page: pageNumber.toString(),
            perPage: paginationPageSize
        });
        let searchQuery
        if(searchItem){          
            searchQuery = "&filter%5Bsearch%5D="+searchItem            
        }else{
            searchQuery = ''
        }        
        if (sortModel?.length) {
            if (sortModel[0].sort == 'desc') {
                query.append("sort", "-" + sortModel[0].colId);
            } else {
                query.append("sort", sortModel[0].colId);
            }
            this.checkboxHeader.next(false)
        }else{
            this.checkboxHeader.next(false) 
        }
        if(searchQuery){
             return query.toString() + searchQuery
        }else{
            return  query.toString()
        }        
    }
}
