import {NgModule}                from '@angular/core';
import {AuthRoutingModule}       from './auth-routing.module';
import {LoginComponent}          from './components/login/login.component';
import {CoreModule}              from '../core.module';
import {RegisterComponent}       from './components/register/register.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {ResetPasswordComponent}  from './components/reset-password/reset-password.component';
import {MatCardModule}           from '@angular/material/card';
import {MatFormFieldModule}      from '@angular/material/form-field';
import {MatInputModule}          from '@angular/material/input';
import {MatIconModule}           from '@angular/material/icon';
import {MatButtonModule}         from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    imports:      [
        CoreModule,
        AuthRoutingModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule
    ],
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        RegisterComponent
    ],
    exports: [
        LoginComponent,
        RegisterComponent
    ]
})
export class AuthModule {
}
