import {Component, EventEmitter,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contact } from '../../models/contact.model';
import { Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector:    'app-index',
    templateUrl: './duplicate-contact-list.component.html',
})
export class DuplicateContactListComponent 
{
    contactList:Contact;
    public event: EventEmitter<any> = new EventEmitter();
    public constructor(
        private router:Router,
        public dialogRef: MatDialogRef<DuplicateContactListComponent>,            
        @Inject(MAT_DIALOG_DATA) public data: {
            duplicateList: any           
        },
    )
    { }

    public ngOnInit()
    {
        this.contactList = this.data?.duplicateList         
    }

    selectedContact(event: MatSelectChange = null){
        const url="#/app/contacts/"+event.value+"/edit"
         window.open(url, '_blank');
         this.dialogRef.close(true)
    }

}
