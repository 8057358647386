import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, EMPTY} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UsersService} from '../../../modules/users/services/users.service';
import {Registrar} from '../models/registrar.model';

@Injectable({providedIn: 'root'})
export class RegisterResolver implements Resolve<any>
{
    private token: string;

    public constructor(
        private router: Router,
        private usersService: UsersService,
    )
    {
    }

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<Registrar> | Promise<Registrar> | Registrar
    {
        this.token = route.paramMap.get('token');

        return this.usersService
        .getUserFromToken(this.token)
        .pipe(catchError(() => {
                this.router.navigate(['/app']).then(r => r);
                    return EMPTY;
                })
            );
    }
}
