import {Injectable}                                                                                                              from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {Observable, of}                                                                                                          from 'rxjs';
import {AuthService}                                                                                                             from '../../core/auth/services/auth.service';
import {switchMap}                                                                                                               from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad
{
    /**
     * Constructor
     */
    public constructor(
        private authService: AuthService,
        private router: Router,
    )
    {
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const redirectUrl = state.url === '/logout' ? '/' : state.url;
        return this.check(redirectUrl);
    }

    public canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const redirectUrl = state.url === '/logout' ? '/' : state.url;
        return this.check(redirectUrl);
    }

    public canLoad(
        route: Route,
        segments: UrlSegment[],
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this.check('/');
    }

    private check(redirectUrl: string): Observable<boolean>
    {
        return this.authService.check().pipe(
            switchMap((authenticated: boolean) => {
                if (!authenticated) {
                    this.router.navigate(['login'], {queryParams: {redirectUrl}}).then(r => r);
                    return of(false);
                }

                return of(true);
            }),
        );
    }
}
