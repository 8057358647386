export const navItems: any[] = [  
    {
        name: 'Activity Dashboard',
        url: '/app',
        icon: 'speed',
    },
    {
        name: "Call Target Dashboard", 
        url: "/app/teams/calls-dashboard", 
        icon: "speed"
    },
    {
        name: "Revenue Dashboard",
        url: "/app/teams/revenue-dashboard",
        icon: "speed"
    },
    // {
    //     name: 'Calendar',
    //     url: '/app/calendar',
    //     icon: 'calendar_today',
    // },
    {
        name: 'Agenda',
        url: '/app/agenda',
        icon: 'calendar_today',
    },
    {
        name: 'Organisations',
        url: '/app/organisations',
        icon: 'apartment',
    },
    {
        name: 'Contacts',
        url: '/app/contacts',
        icon: 'contacts',
    },
    {
        name: 'Opportunities',
        url: '/app/opportunities',
        icon: 'business_center',
    },
    // {
    //     name: 'Tickets',
    //     url: '/app/tickets',
    //     icon: 'support_agent',
    // },
    {
        name: 'Assets',
        url: '/app/assets',
        icon: 'shopping_basket',
    },
    {
        name: 'Quotes',
        url: '/app/quotes',
        icon: 'loop',
    },
    {
        name: 'Sales Orders',
        url: '/app/sale-orders',
        icon: 'shopping_basket',
    },    
    // {
    //     name: 'Purchase Orders',
    //     url: '/app/purchase-orders',
    //     icon: 'add_shopping_cart',
    // },
    {
        name: 'Projects',
        url: '/app/projects',
        icon: 'location_on',
    },
    {
        name: 'Price Lists',
        url: '/app/price-lists',
        icon: 'euro_symbol',
    }     
];
export const activityNavItems: any[] = [
    {
        name: 'Task',
        url: '/app/tasks',
        icon: 'check_circle',
    },
    // {
    //     name: 'Calls',
    //     url: '/app/calls',
    //     icon: 'call',
    // },
    {
        name: 'Call Management',
        url:  '/app/call_management',
        icon: 'call',
    },
];

export const navigationItems: any[] = [
    {
        name: 'Targets',
        url: '/app/targets',
        icon: 'apartment',
    },  
    {
        name: 'Pipelines',
        url: '/app/pipelines',
        icon: 'circle',
    },
    // Pankaj,27-july-23,Ticket-3630,add team links
    {
        name: 'Teams',
        url: '/app/teams',
        icon: 'people',
    },
    // pankaj,12-Feb-23,Ticket-4644,move user and discount code to management section
    {
        name: 'Users',
        url: '/app/users',
        icon: 'groups',
    },
    {        
        name: 'Promotions',
        url: '/app/promotions',
        icon: 'apartment',
    },
    {
        name: 'Discount Code',
        url: '/app/discount-code',
        icon: 'policy',
    },
]
export const productNavItems: any[] = [
    {
        name: 'Products',
        url: '/app/products',
        icon: 'shopping_basket',
    },
    {
        name: 'Product Groups',
        url: '/app/products/opt/groups',
        icon: 'business_center',
    },
    {
        name: 'Product Categories',
        url: '/app/products/opt/categories',
        icon: 'business_center',
    },
];

export const settingNavItems: any[] = [
        {
        name: 'Roles',
        url: '/app/roles',
        icon: 'policy',
    },
    {
        name: 'Quote Validity Days',
        url: '/app/quote_Validity',
        icon: 'policy',
    },
    {
        name: 'Vat Rate',
        url: '/app/vat-rate',
        icon: 'policy',
    },
    
    // {
    //     name: 'Organization Email Mapping',
    //     url: '/app/organization-email-mapping',
    //     icon: 'email',
    // },
];
