import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'externalUrl',
})
export class ExternalUrlPipe implements PipeTransform
{
    public transform(href: string): string
    {
        return /^https?/.test(href) ? href : `//${href}`;
    }
}
