import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-value-renderer',
  templateUrl: './value-renderer.component.html',
})
export class ValueRendererComponent implements ICellRendererAngularComp {
  params: any;
  callback: (params: any) => void;

  agInit(params: any): void {
    this.params = params;
    this.callback = params.context.callCustomFunction;
  }

  refresh(params: any): boolean {
    return false;
  }

  onClick(event: Event) {
    event.preventDefault();
    this.callback(this.params);
  }
}
