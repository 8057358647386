import { Component, OnInit } from "@angular/core";

@Component({
  selector: "modal-content",
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.scss"]
})
export class CommentsComponent implements OnInit {
  public title?: string;
  public id?: any;
  public comments?: any;

  public constructor() {}

  public ngOnInit() {
    console.log(this.id);
    console.log(this.comments);
  }
}
