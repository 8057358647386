import {Component, ViewChild}                  from '@angular/core';
import {AuthService} from '../../../../auth/services/auth.service';
import {navItems} from '../../../../navigation';
import {productNavItems} from '../../../../navigation';
import {activityNavItems} from '../../../../navigation';
import {navigationItems} from '../../../../navigation';
import {settingNavItems} from '../../../../navigation';
import {CommentsComponent} from '../../../../../shared/components/comments/comments.component';
import {MatAccordion} from '@angular/material/expansion';
import {BaseService} from '../../../../../shared/services/base.service'
import { SettingsService } from '../../../../auth/services/settings.service';
@Component({
    selector:    'app-dashboard',
    templateUrl: './layout.component.html',
    styleUrls:   ['./layout.component.scss'],
})
export class LayoutComponent
{
    @ViewChild(MatAccordion) accordion: MatAccordion;

    public navItems: any[] = navItems;
    public productNavItems: any[] = productNavItems;
    public navigationItems: any[] = navigationItems;
    public activityNavItems: any[] = activityNavItems;
    public settingNavItems: any[] = settingNavItems;
    public year: number = (new Date).getFullYear();
    public comments = [
        {
            id:         Math.random(),
            body:       'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            added_by:   {
                id:      '1',
                name:    'John Smith',
                profile: '../../../../../../assets/img/avatars/profile-avatar.png',
            },
            created_at: '2022-02-22 08:45:00',
            replies:    [
                {
                    body:       'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                    added_by:   {
                        id:      '1',
                        name:    'John Smith',
                        profile: '../../../../../../assets/img/avatars/profile-avatar.png',
                    },
                    created_at: '2022-02-22 08:45:00',
                },
                {
                    body:       'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                    added_by:   {
                        id:      '1',
                        name:    'John Smith',
                        profile: '../../../../../../assets/img/avatars/profile-avatar.png',
                    },
                    created_at: '2022-02-22 08:45:00',
                },
            ],
        },
    ];
    userName:any;
    public constructor(
        private authService: AuthService,private baseService:BaseService,private settingService: SettingsService
    )
    {
        this.userName=this.settingService.authUser.name
    }

    public get sidebarMinimised(): boolean
    {
        return JSON.parse(localStorage.getItem('sidebar-minimised'));
    }

    public set sidebarMinimised(state: boolean)
    {
        localStorage.setItem('sidebar-minimised', JSON.stringify(state));
    }

    public toggleSidebar(event)
    {
        this.sidebarMinimised = event;   
    }

    anotherFunction(){
        this.baseService.menuPopulation.next(true)
    }

    public logout(): void
    {
        this.authService.logout();
        location.reload();
    }

    public openModal(id: any, comments)
    {
        const initialState = {
            class: "modal-xl",
            initialState: {
                title: "Comments",
                id: id,
                comments: comments
            }
        };

        // this.modalRef = this.modalService.show(CommentsComponent, initialState);
    }
}
