
import { Component, OnInit, Input, OnDestroy, HostBinding, ElementRef, DoCheck, Output, EventEmitter} from '@angular/core'
import { ControlValueAccessor, NgControl, FormGroupDirective, NgForm } from '@angular/forms'
import { MatFormFieldControl } from '@angular/material/form-field'
import { mixinErrorState, ErrorStateMatcher, CanUpdateErrorState } from '@angular/material/core'
import { Subject } from 'rxjs'
import { FocusMonitor } from '@angular/cdk/a11y'
import { coerceBooleanProperty } from '@angular/cdk/coercion'

class SbMatControlBase {
  constructor(
    public _elementRef: ElementRef,
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    public _focusMonitor: FocusMonitor,
    public ngControl: NgControl,
  ) { }
}

const _SbMatControlBaseMixinBase:

    typeof SbMatControlBase =
        ((SbMatControlBase))

@Component({
  template: ''
})
export  class SbMatControl extends _SbMatControlBaseMixinBase implements ControlValueAccessor, OnInit, OnDestroy, MatFormFieldControl<any>, DoCheck, CanUpdateErrorState {
  constructor(
    public _elementRef: ElementRef,
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    public _focusMonitor: FocusMonitor,
    public ngControl: NgControl,
  ) {
    super(_elementRef, _defaultErrorStateMatcher, _parentForm, _parentFormGroup, _focusMonitor, ngControl)
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this
    }
    _focusMonitor.monitor(_elementRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin
      this.stateChanges.next()
    })
  }
  updateErrorState(): void {
    throw new Error('Method not implemented.')
  }
  errorState: boolean

  static nextId = 0
  @HostBinding() id = `sb-mat-control-${SbMatControl.nextId++}`
  controlType = 'sb-mat-control'
  stateChanges = new Subject<void>()
  @Input() errorStateMatcher!: ErrorStateMatcher

  @Input()
  get value(): any {
    return this._getValue()
  }
  set value(value: any) {
    this._setValue(value)
    this._processValue()
    if (this._onChange) this._onChange(value)
    this.stateChanges.next()
  }
  protected _value: any
  protected _getValue = () => this._value
  protected _setValue = (value: any) => this._value = value
  protected _processValue = () => {}

  @Input()
  get disabled(): boolean {
    return this._getDisabled()
  }
  set disabled(disabled: boolean) {
    this._setDisabled(disabled)
    this._processDisabled()
    this.stateChanges.next()
  }
  protected _disabled = false
  protected _getDisabled = () => this._disabled
  protected _setDisabled = (disabled: any) => this._disabled = coerceBooleanProperty(disabled)
  protected _processDisabled = () => {}

  @Input()
  get placeholder(): string {
    return this._getPlaceholder()
  }
  set placeholder(placeholder: string) {
    this._setPlaceholder(placeholder)
    this._processPlaceholder()
    this.stateChanges.next()
  }
  protected _placeholder!: string
  protected _getPlaceholder = () => this._placeholder
  protected _setPlaceholder = (placeholder: string) => this._placeholder = placeholder
  protected _processPlaceholder = () => {}

  @Input()
  get required(): boolean {
    return this._getRequired()
  }
  set required(required: boolean) {
    this._setRequired(required)
    this._processRequired()
    this.stateChanges.next()
  }
  protected _required = false
  protected _getRequired = () => this._required
  protected _setRequired = (required: any) => this._required = coerceBooleanProperty(required)
  protected _processRequired = () => {}
  
  @Input()
  get readonly(): boolean | string {
    return this._getReadonly()
  }
  set readonly(readonly: boolean | string) {
    this._setReadonly(readonly)
    this._processReadonly()
    this.stateChanges.next()
  }
  protected _readonly: boolean | string = false
  protected _getReadonly = () => this._readonly
  protected _setReadonly = (readonly: boolean | string) => this._readonly = coerceBooleanProperty(readonly)
  protected _processReadonly = () => {}
  
  get empty(): boolean {
    return this._getEmpty()
  }
  protected _getEmpty():boolean {
    return false
  }

  focused = false
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty
  }

  @HostBinding('attr.aria-describedby') describedBy = ''
  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ')
  }

  onContainerClick(event: MouseEvent): void {
  }

  writeValue(value: any): void {
    this.value = value || null
  }

  _onChange!: (_: any) => void
  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn
  }

  _onTouched!: () => void
  registerOnTouched(fn: () => void): void {
    this._onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy(): void {
    this.stateChanges.complete()
    this._focusMonitor.stopMonitoring(this._elementRef.nativeElement)
  }

  ngDoCheck() {
    if (this.ngControl) {
      // this.updateErrorState()
    }
  }

  @Output() change = new EventEmitter()
  protected emitChange($event: any = null) {
    this.change.emit(this.value)
  }
}
  
