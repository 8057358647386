import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseService } from './base.service';
import { SettingsService } from '../../core/auth/services/settings.service';

@Injectable({
    providedIn: 'root'
})
export class CommonListValuesService extends BaseService {
    public constructor(private httpClient: HttpClient, private settingsService: SettingsService) {
        super();
    }

    public listValues(value: string, sort: string = 'label'): Observable<any> {
        const params = { 'filter[code]': value, 'sort': sort };

        return this.httpClient.get(`${this.apiUrl}/common-list-values`, { params: params })
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public list(value: string): Observable<any> {
        const params = { 'filter[code]': value};

        return this.httpClient.get(`${this.apiUrl}/common-list-values`, { params: params })
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public list_number_multiple(values: string[]): Observable<any> {
        const params = values.reduce((acc, val, i) => {
            acc[`filter[${i}]`] = val;
            return acc;
        }, {});
        params['orderBy'] = 'direction';
        return this.httpClient.get(`${this.apiUrl}/common-list-values/multiple`, { params: params },)
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }


    public listValuesMultiple(values: string[]): Observable<any> {
        const params = values.reduce((acc, val, i) => {
            acc[`filter[${i}]`] = val;
            return acc;
        }, {});

        return this.httpClient.get(`${this.apiUrl}/common-list-values/multiple`, { params: params })
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public listValuesMultipleWithCache(values: string[]): Observable<any> {
        const clvs = {};
        let notInCache = false;
        values.forEach(v => {
            const hasCache = this.settingsService.getValue(v);
            if (!hasCache) {
                notInCache = true;
                return;
            };
            clvs[v] = hasCache;
        });
        if (!notInCache) return of(clvs);

        const params = values.reduce((acc, val, i) => {
            acc[`filter[${i}]`] = val;
            return acc;
        }, {});

        return this.httpClient.get(`${this.apiUrl}/common-list-values/multiple`, { params: params })
            .pipe(switchMap((response: any) => {
                values.forEach(v => {
                    this.settingsService.setValue(v, response[v]);
                })
                return of(response);
            }));
    }

}
