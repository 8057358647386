import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseService } from './base.service';
import { Params } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export abstract class ApiService extends BaseService {
    public entity: string;

    protected constructor(protected httpClient: HttpClient) {
        super();
    }

    public list(params: Params = null): Observable<any> {
        return this.httpClient
            .get(`${this.apiUrl}/${this.entity}`, { params: params })
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public show(id: string | number): Observable<any> {
        return this.httpClient
            .get<any>(`${this.apiUrl}/${this.entity}/${id}`)
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public create(data: any) {
        return this.httpClient
            .post<any>(`${this.apiUrl}/${this.entity}`, data)
            .pipe(switchMap((response: any) => of(response)));
    }

    public update(id: string | number, data: any): Observable<any> {
        return this.httpClient
            .put<any>(`${this.apiUrl}/${this.entity}/${id}`, data)
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public delete(id: string | number): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiUrl}/${this.entity}/${id}`);
    }

    public handleError<T>(result?: T)
    {
        return (error: any): Observable<T> => {
            console.error(error);
            return of(result as T);
        };
    }
}
