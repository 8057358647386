import {AgRendererComponent} from 'ag-grid-angular';
import {Component}           from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community';
import {Router} from '@angular/router'
@Component({
    selector:    'activity-link-renderer',
    templateUrl: './activity-link-renderer.component.html',
})
export class ActivityLinkRendererComponent implements AgRendererComponent
{
    constructor(private router:Router){

    }
    public params!: ICellRendererParams;

    public agInit(params: ICellRendererParams): void    {
        
        this.params = params;        
    }

    public refresh(params: ICellRendererParams): boolean
    {
        return false;
    }

    public navigateToRoute(params){        
        if(params.data.actionableOpp){
            return "/app/opportunities/"+params?.data?.actionableOpp?.id
         }else if(params.data.actionableOrg){
            return "/app/organisations/"+params?.data?.actionableOrg?.id
         }else if(params.data.actionableCon){
            return "/app/contacts/"+params?.data?.actionableCon?.id
         }
    }
}
