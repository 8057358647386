import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { UsersService } from "../../../../modules/users/services/users.service";
import { Registrar } from "../../models/registrar.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomValidators, MustMatch } from '../../../../shared/validators/validators';

@Component({
  selector: "app-dashboard",
  templateUrl: "./register.component.html"
})
export class RegisterComponent implements OnInit {
  public registrar: Registrar;
  public registerForm: FormGroup;
  public error: any;
  public submitted: boolean = false;

  public constructor(
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private userService: UsersService
  ) {
    this.registrar = this.route.snapshot.data.user.data;
  }

  public get f() {
    return this.registerForm.controls;
  }

  public ngOnInit(): void {
    this.registerForm = this.fb.group({
      first_name: [this.registrar.first_name, Validators.required],
      last_name: [this.registrar.last_name, Validators.required],
      email:   [this.registrar.email, [Validators.required, Validators.email,CustomValidators.removeSpaces]],
      password: ["", Validators.required],
      password_confirmation: ["", Validators.required]
    }
    , {
      validator: MustMatch('password', 'password_confirmation')
    }
    );
  }

  public onSubmit(): void {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.registerForm.disable();
    this.userService
      .updateUserFromToken(this.registrar.invite_token, this.registerForm.value)
      .subscribe(
        () => {
          this.snackBar.open(
            "You have successfully registered your account.",
            "Ok.",
            { duration: 2000 }
          );
          this.router.navigate(["app"]).then(r => r);
        },
        response => {
          this.registerForm.enable();
           this.snackBar.open(response.error.message, 'Ok.', {duration: 2000});
          return
        }
      );
  }
}
