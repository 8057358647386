import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { ApiService } from '../../../shared/services/api.service';
import { switchMap } from 'rxjs/operators';
import { Registrar } from '../../../core/auth/models/registrar.model';
import { AbstractControl, FormGroup } from '@angular/forms';
import * as moment from 'moment'
import { SettingsService } from '../../../core/auth/services/settings.service';

function m(input: any = null) {
  return input ? moment(input) : moment()
}
@Injectable({
  providedIn: 'root'
})
export class UsersService extends ApiService {
  public entity: string = 'users';
  public user;

  public constructor(
    public httpClient: HttpClient,
    public location: Location,
    private settingsServices: SettingsService
  ) {
    super(httpClient);
  }

  public goBack(): void {
    this.location.back();
  }

  public search(term: string): Observable<any> {
    if (!term.trim()) {
      return of([]);
    }
    return this.httpClient.get<any>(`${this.apiUrl}/?first_name=${term}`).pipe();
  }

  public getUserFromToken(token: string | number): Observable<any> {
    return this.httpClient
      .get<Registrar>(`${this.apiUrl}/users/join/${token}`)
      .pipe(switchMap((response: any) => {
        return of(response);
      }));
  }

  public updateUserFromToken(token: string, data: any): Observable<any> {
    const url = `${this.apiUrl}/users/join/${token}`;
    return this.httpClient
      .put<any>(url, data).pipe(switchMap((response: any) => {
        return of(response);
      }));
  }

  // public startDateValidator(control) {
  //   const selectedDate = new Date(control.value);
  //   const currentDate = new Date();
  //   currentDate.setHours(0, 0, 0, 0);
  //   return selectedDate < currentDate ? { invalidStartDate: true } : null;
  // }
  public startDateValidator(control) {
    const selectedDate = new Date(control.value);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return selectedDate < currentDate ? { invalidStartDate: true } : null;
}
 selectedEndDate:any;
  public endDateValidator(control) {    
    const selectedDate = new Date(control.value);
    const currentDate = new Date();
    if (selectedDate < currentDate && !control.errors?.invalidEndDate) {
      return { invalidEndDate: true };
    }
    return null;
  }

  public dateRangeValidator(formGroup: FormGroup) {    
    const startDateControl = formGroup.get('start_at');
    const endDateControl = formGroup.get('end_at');
    const startDate = new Date(startDateControl.value);
    const endDate = new Date(endDateControl.value);
    const currentDate = new Date();    
// if (!endDateControl.value) {
    //   endDateControl.setErrors({ required: true });
    //   return { required: true };
    // }
    if (endDate < currentDate) {
      if (formGroup.controls['end_at'].value) {
        endDateControl.setErrors({ invalidEndDate: true });
        return { invalidEndDate: true };
      }
    } else if (startDate > endDate) {
      endDateControl.setErrors({ invalidEndDate: true });
      return { invalidDateRange: true };
    }
    endDateControl.setErrors(null);
    return null;
  }

  // if end date is required
  public dateRangeValidation(formGroup: FormGroup){    
    const startDateControl = formGroup.get('start_at');
    const endDateControl = formGroup.get('end_at');
    const startDate = new Date(startDateControl.value);
    const endDate = new Date(endDateControl.value);
    const currentDate = new Date();
    if (!endDateControl.value) {
      endDateControl.setErrors({ required: true });
      return { required: true };
    }
    if (startDate > endDate) {
      endDateControl.setErrors({ invalidEndDate: true });
      return { invalidDateRange: true };
    }
    endDateControl.setErrors(null);
    return null;
  }
  
  public listWithCache() {
    const cache = this.settingsServices.getValue("users");
    if (cache) {
      return of(cache);
    }
    return this.list().pipe(switchMap((response: any) => {
      this.settingsServices.setValue("users", response);
      return of(response);
    }));
  }

  // Add this function in your component class
 // Add this function in your component class
positiveIntegerValidator(control: AbstractControl): { [key: string]: any } | null {
  const value = control.value;
  // Allow empty values
  if (value === null || value === undefined || value === '') {
    return null;
  }
  // Check if the value contains only numeric characters (including positive integers with non-numeric characters)
  if (!/^[0-9]+$/.test(value)) {
    return { 'positiveInteger': true }; // Validation failed
  }
  const parsedValue = parseInt(value, 10);
  // Check if the parsed value is a positive integer
  if (isNaN(parsedValue) || parsedValue <= 0 || parsedValue !== parsedValue) {
    return { 'positiveInteger': true }; // Validation failed
  }
  return null;  // Validation passed
}

// if end date is required
public validationDateRange(formGroup: FormGroup){    
  const startDateControl = formGroup.get('start_date');
  const endDateControl = formGroup.get('end_date');
  const startDate = new Date(startDateControl.value);
  const endDate = new Date(endDateControl.value);
  const currentDate = new Date();
  if (!endDateControl.value) {
    endDateControl.setErrors({ required: true });
    return { required: true };
  }
  if (startDate > endDate) {
    endDateControl.setErrors({ invalidEndDate: true });
    return { invalidDateRange: true };
  }
  endDateControl.setErrors(null);
  return null;
}
}



