import { Component, OnInit } from '@angular/core';
import {MatDialog}           from '@angular/material/dialog';

@Component({
  selector: 'app-show-order',
  templateUrl: './show-order.component.html',
  styleUrls: ['./show-order.component.scss']
})
export class ShowOrderComponent implements OnInit {

  public constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }
}
