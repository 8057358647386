import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from "@angular/router";

import { HttpErrorResponse } from '@angular/common/http';
import { environment } from "../../../../../environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: "app-dashboard",
    templateUrl: "./login.component.html"
})
export class LoginComponent implements OnInit {
    public loginForm: FormGroup;
    public error: any;
    public submitted: boolean = false;
    private user: any;

    public constructor(private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute, private router: Router, private fb: FormBuilder, private authService: AuthService) {
        this.user = environment.apiUrl;
    }

    public get f() {
        return this.loginForm.controls;
    }

    public ngOnInit(): void {
        this.loginForm = this.fb.group({
            email: [this.user.email ?? null, [Validators.required, Validators.email]],
            password: [this.user.password ?? null, Validators.required]
        });
    }

    public onSubmit(): void {
        this.submitted = true;

        if (this.loginForm.invalid) {
            this.submitted = false;
            return;
        }

        this.loginForm.disable();

        this.authService.login(this.loginForm.value).then(() => {
            const redirectUrl = this.activatedRoute.snapshot.queryParamMap.get("redirectUrl") || "/signed-in-redirect";
            this.snackBar.open("Signed in successfully.", "Ok.", { duration: 2000 });
            this.router.navigateByUrl(redirectUrl).then(r => r);
        }).catch((response: HttpErrorResponse) => {
            this.loginForm.enable();
            console.log("response", response.error.error);
            this.error = response.error.error;
        }).finally(() => this.submitted = false);
    }
}
