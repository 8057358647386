import { Component, Input, HostBinding, ElementRef, Optional, Self, ViewChild, Inject, Output, EventEmitter } from '@angular/core'
import { FormGroup, FormBuilder, FormControl, NgControl, FormGroupDirective, NgForm } from '@angular/forms'
import { MatFormFieldControl } from '@angular/material/form-field'
import { ErrorStateMatcher, MAT_DATE_FORMATS } from '@angular/material/core'
import { FocusMonitor } from '@angular/cdk/a11y'
import { SbMatControl } from '../../base-classes/sb-mat-control.class'

@Component({
  selector: 'app-time-control',
  templateUrl: './time-control.component.html',
  styleUrls: ['./time-control.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: TimeControlComponent }
  ],
})

export class TimeControlComponent extends SbMatControl {
  //overrides
  @Output() timeChange = new EventEmitter()
  @HostBinding() id = `sb-time-${TimeControlComponent.nextId++}`
  controlType = 'sb-time'
  _processValue = () => this.updateForm()
  _getEmpty = () => {
    return (this.hour.value == null && this.minute.value == null)
  }
  _processDisabled = () => {
    this.form ? (this.disabled ? this.form.disable() : this.form.enable()) : null
  }
  
  constructor(
    public _elementRef: ElementRef<HTMLElement>,
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    public _focusMonitor: FocusMonitor,
    @Optional() @Self() public ngControl: NgControl,
    public formBuilder: FormBuilder,
    @Inject(MAT_DATE_FORMATS) public dateFormat: any
  ) {
      super(_elementRef, _defaultErrorStateMatcher, _parentForm, _parentFormGroup, _focusMonitor, ngControl)
    }
    
    hourList: string[] = Array(12).fill(0).map((c,i) => c = i+1+'')
    minuteList: string[]

  @Input() minInterval: number = 5
  private maxHour!: number
  private minHour!: number
  private maxMinute: number = 59
  @Input() timeFormat: number = null
  private minMinute: number = 0
  ngOnInit() {
    this.form = this.formBuilder.group({
      hour: null,
      minute: null,
      ampm: null,
    })
    this.hour =  this.form.get('hour') as  FormControl
    this.minute =  this.form.get('minute') as  FormControl
    this.ampm = this.form.get('ampm') as FormControl
    if (this.timeFormat == 24) {
      this.maxHour = 23
      this.minHour = 0
    } else {
      this.maxHour = 12
      this.minHour = 1
    }
    
    // if (this.timeFormat == 24) {this.hourList = Array(24).fill(0).map((c, i) => c = (i < 10 ? '0' : '') + i)
    // this.minuteList= Array(60/this.minInterval).fill(0).map((c, i) => c = (i<(10 / this.minInterval)? '0' : '') + i*this.minInterval)
    if (this.timeFormat == 24){
      this.hourList = ["00"].concat(Array.from({ length: 10 }, (_, i) => (i + 8).toString().padStart(2, '0')));
      this.minuteList= Array(60/this.minInterval).fill(0).map((c, i) => c = (i<(10 / this.minInterval)? '0' : '') + i*this.minInterval)
    }
    if (this._value) this.updateForm()
    this._processDisabled()
  }
  @Input() endTime: any;
  //sb date time specific
  @Input() max: string = ''
  @Input() min: string = ''
  @Input() clear: string | null = null
  

  @Input()
  set minuteIncrement(minuteIncrement) {
    
    this._minuteIncrement = [1, 2, 3, 4, 5, 6, 10, 12, 15, 20, 30].indexOf(Number(minuteIncrement)) >= 0 ? minuteIncrement : 5
  }
  get minuteIncrement(): number {
    
    return this._minuteIncrement
  }
  private _minuteIncrement: number = 5

  form!: FormGroup
  hour!: FormControl
  minute!: FormControl
  ampm!: FormControl

  private convert12to24(hour: string, minute: string, ampm: string): any {
    let h = Number(hour), m = Number(minute)
    h = ampm == 'PM' && h < 12 ? (h + 12) % 24 : h
    return { hour: (h + '').padStart(2, '0'), minute: (m + '').padStart(2, '0'), ampm: null }
  }
  private timestringto24(time: string): any {
    let h = Number(time.split(':')[0]), m = Number(time.split(':')[1])
    return { hour: (h + '').padStart(2, '0'), minute: (m + '').padStart(2, '0'), ampm: null }
  }
  private timestringto12(time: string): any {
    let h = Number(time.split(':')[0]),
      m = Number(time.split(':')[1]),
      a = h >= 12 ? 'PM' : 'AM'
    h = h == 0 ? 12 : (h > 12 ? h - 12 : h)
    return { hour: h, minute: (m + '').padStart(2, '0'), ampm: a }
  }

  private _savedValue: any
  private processValueChanges() {
    if (this.hour.value == null) {
      this.minute.patchValue(null)
      this.ampm.patchValue(null)
      if (!this._value) return
      else {
        this._value = null
        if (this._onChange) this._onChange(this._value)
        this.stateChanges.next()
        this._savedValue = null
        this.emitChange()
        return
      }
    }
    if (this.minute.value == null) this.minute.patchValue('00')
    if (this.ampm.value == null) this.ampm.patchValue('AM')
    if (this.timeFormat == 24) this._value = this.hour.value + ':' + this.minute.value
    else {
      let val =  this.convert12to24(this.hour.value, this.minute.value, this.ampm.value)
      this._value = val.hour + ':' + val.minute
    }
    if (!this._savedValue || this._value != this._savedValue) {
      if (this._onChange) this._onChange(this._value)
      this.stateChanges.next()
      this._savedValue = this._value
      this.emitChange()
    }
  }

  private _hasInternalFocus: boolean = false
  private _overallFocus: boolean = false
  onFocus($event: any) {

  }
  onBlur($event: any) {
    this._hasInternalFocus = false
    setTimeout(()=> this.processBlurInternal())
  }
  private processBlurInternal = () => this._hasInternalFocus ? null : this.processBlur()
  
  private processBlur() {
    this._overallFocus = false
    if (this._onTouched) this._onTouched()
    this.processValueChanges()
  }
  
  private updateForm() {
    if (!this.form) return
    if (!this._value) {
      this.form.patchValue({ hour: null, minute: null, ampm: null })
      return 
    }
    if (Number(this._value.split(':')[0]) > 23 || !this._value.split(':')[1] || Number(this._value.split(':')[1]) > 59) return
    if (this.timeFormat == 12) this.form.patchValue(this.timestringto12(this._value))
    else this.form.patchValue(this.timestringto24(this._value))
  }

  showClear = () => !(this.clear && (this.clear+'').toLowerCase() == 'off') && !this.disabled
  
  clearDate($event: any) {
    $event.stopPropagation()
    this.value = null
  }

  @ViewChild('hourElement') hourElement!: ElementRef<any>
  @ViewChild('minElement') minElement!: ElementRef<any>
  @ViewChild('ampmElement') ampmElement!: ElementRef<any>

  hourChange(val: any) {
    this.hour.patchValue(val.value)
    this.hrs=val.value
    this.timeChange.emit(this.hrs+":"+this.mins)
      }
  hrs:any="00";
  mins:any="00";
  minChange(val: any) {
    this.mins=val.value
     this.minute.patchValue(val.value)
     if(this.endTime){
      const timeString = this.endTime.value;
      const [hours, minutes] = timeString.split(':')
      if(hours){
        this.hrs=hours
      }else{
        this.hrs='00'
      }
     }    
    this.timeChange.emit(this.hrs+":"+this.mins)
  
  }

  ampmKeyup($event: any) {    
    let changeKeys = ['ArrowDown', 'ArrowUp', 'PageDown', 'PageUp', ' ']
    let val = this.ampmElement.nativeElement.value
    if ($event.key == 'p' || $event.key == 'P') val = 'PM'
    else if ($event.key == 'a' || $event.key == 'A') val = 'AM'
    else if (changeKeys.indexOf($event.key) >= 0) val = val == 'AM' ? 'PM' : 'AM'

    if (this.ampmElement.nativeElement.value != val) {
      this.ampm.patchValue(val)
      this.ampmElement.nativeElement.select()
    }
  }

  get showColon() {
    return this.hour.value !== null || this.minute.value !== null
  }

  formClick($event: any) {
  }

  
}