import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import { ActivatedRoute, Router } from "@angular/router";

import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomValidators, MustMatch } from '../../../../shared/validators/validators';
import { Registrar } from '../../models/registrar.model';
import { DataService } from '../../../../shared/services/data.service';

@Component({
    selector: "app-dashboard",
    templateUrl: "./reset-password.component.html"
})
export class ResetPasswordComponent implements OnInit {
    public token: string;
    public resetForm: FormGroup;
    public error: any;
    public submitted: boolean = false;
    public registrar: Registrar;

    public constructor(private snackBar: MatSnackBar,public dataService: DataService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private authService: AuthService) {
        this.token = this.route.snapshot.paramMap.get("token");
    }

    public get form() {
        return this.resetForm.controls;
    }

    public ngOnInit(): void {
        this.resetForm = this.fb.group({
            email:   ["", [Validators.required, Validators.email,CustomValidators.removeSpaces]],
            password: ["", Validators.required],
            password_confirmation: ["", Validators.required]
          }
          , {
            validator: MustMatch('password', 'password_confirmation')
          }
          );
          this.getUserList();

    }

    public onSubmit(): void {
        this.submitted = true;
        if (this.resetForm.invalid) {
            return;
        }
        this.resetForm.disable();
        this.authService.resetPassword(this.token, this.resetForm.value).subscribe(
            response => {
                this.snackBar.open("Reset password successfull.", "Ok.", { duration: 2000 });
                this.router.navigateByUrl("login").then(r => r);
            },
            (error: HttpErrorResponse) => {
                this.resetForm.enable();
                this.error = error;                
                this.snackBar.open(this.error.error.message,"Ok.",{ duration: 2000 });
            }
        );
    }
    users:any
    getUserList(){
        this.dataService.list("reset-password/"+this.token).subscribe(
          response => {
            this.users = response.data;
            this.resetForm.patchValue({email:this.users.email})
        },
          error => this.dataService.handleError(error)
        );
      }
}
