import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe}                            from '@angular/common';
import {parseInt}                               from 'lodash';

/*
 * Convert bytes into the largest possible unit.
 * Takes a precision argument that defaults to 2.
 * Usage:
 *   bytes | fileSize:precision
 * Example:
 *   {{ 1024 |  fileSize}}
 *   formats to: 1 KB
 */
@Pipe({
    name: 'fileSize',
})
export class FileSizePipe implements PipeTransform
{
    public decimalPipe: DecimalPipe;
    private sizes = [
        'Bytes',
        'KB',
        'MB',
        'GB',
        'TB',
        'PB',
    ];

    public constructor(@Inject(LOCALE_ID) localeId)
    {
        this.decimalPipe = new DecimalPipe(localeId);
    }

    public transform(bytes: string | number = 0, precision: number = 2): string
    {
        if (typeof bytes === 'string') {
            bytes = parseInt(bytes);
        }

        if (bytes === 0) {
            return 'n/a';
        }

        const i = Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), this.sizes.length - 1);

        if (i === 0) {
            return `${bytes} ${this.sizes[i]}`;
        }

        return `${(bytes / (1024 ** i)).toFixed(1)} ${this.sizes[i]}`;
    }
}
