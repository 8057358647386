import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'parseJson',
})
export class ParseJsonPipe implements PipeTransform
{
    public transform(value: string, key?: string): string
    {
        const parsedContent = JSON.parse(value);
        return key && parsedContent && key in parsedContent ? parsedContent[key] : parsedContent;
    }
}
