import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'parseHtml',
})
export class ParseHtmlPipe implements PipeTransform
{
    public transform(value: string): string
    {
        return value ? value.replace(/<[^>]*>/g, '') : null;
    }
}
